<template>
  <div class="news-detail-container" v-if="detail">
    <div
      class="news-detail-fixed-container"
      :class="{
        scrollObj: !$isMobile,
        'mobile-type': $isMobile,
        'pc-type': !$isMobile,
        hidden: $isMobile && getBottom
      }"
    >
      <div class="fixed__publish">
        <p>{{ detail.publish_at }}</p>
      </div>
      <div class="fixed__main-content">
        <p>{{ detail.title }}</p>
      </div>
    </div>
    <div
      class="main-news-detail-body"
      ref="p-container"
      v-ahtml="detail.content"
    >
      <!-- <p class="img scrollObj">
        <img src="@/assets/images/news/d-1.png" alt="" />
      </p>
      <p class="text scrollObj">
        缪斯设计奖（MUSE Design Awards）是国际奖项协会（International Awards
        Associate）旗下的全球性奖项赛事之一，旨在培养和推广“设计缪斯”，推动设计发展更上一层楼。面向建筑、室内、产品、时尚等广泛领域征集设计作品，发掘和支持各行业处在上升期的设计师。参赛者需具备独特的才能，不论通过传统材料或是电子媒介，能够以其概念、想法或设计激发人们的灵感。
      </p>
      <p class="text scrollObj">
        A MUSE's creation is never thoughtless. It is crafted meticulously to
        usher forth new horizons, while serving as waypoints for those who come
        after. The MUSE Design Awards honors design professionals of various
        expertise, whose works carve a path forward, while leaving their marks
        in history. They ascend, and take their rightful place as a MUSE.
      </p>
      <p class="img scrollObj">
        <img src="@/assets/images/news/d-2.png" alt="" />
      </p>
      <p class="text scrollObj">
        <strong>项目概述 | Project Overview </strong>
      </p>
      <p class="text scrollObj">
        望江府交通便利，于北院大道东侧、黄长路南侧，楼宇外遍布百分之30绿地，营造出绿色、阳光、舒适、温暖的生活环境。
      </p>
      <p class="text scrollObj">
        Wangjiangfu has convenient transportation. It is located on the east
        side of Beiyuan Avenue and the south side of Huangchang road. 30% green
        space is spread outside the building, creating a green, sunny,
        comfortable and warm living environment.
      </p>
      <p class="text scrollObj">
        本案以创意的思索拆分经典的宝格丽戒指元素。接待区上空点缀着一串“会发光的珍珠项链”，与金色的几何台面共同重构时尚新势力。旁侧，蜿蜒盘旋的彩色景观带一直延伸至尽头，那梦幻且耀眼的色彩组合完美契合宝格丽设计的精髓，而精致的镶嵌形状和创新的呈现方式则引领人们深入感受艺术的魅力。
      </p>
      <p class="text scrollObj">
        The design extracts classical elements of Bvlgari rings. The lighting
        fixture at the reception area resembles a glowing pearl necklace, which
        adds a modern touch to the space together with the golden geometric
        reception desk beneath.At the reception foyer, a twisting colorful belt
        stretches flexibly in the air. Its attractive color palette perfectly is
        consistent with the quintessence of Bvlgari, and the exquisite,
        innovative mosaic-like pattern embodies the charm of art.
      </p>
      <p class="text scrollObj">
        富有创意的功能布局和陈设也给人耳目一新的感觉。
      </p>
      <p class="text scrollObj">
        The creative functional layout and furnishings bring a refreshing
        feeling as well.
      </p>
      <p class="img scrollObj">
        <img src="@/assets/images/news/d-3.png" alt="" />
      </p>
      <p class="text scrollObj">
        此次项目运用多种建筑环保材料，墙纸、管材、漆料、以及多处环保照明，这是一种以节约电能、保护环境为目的的照明系统。通过科学的照明设计，利用高效、安全、优质的照明电器产品，创造出一个舒适、经济、有益的照明环境。
      </p>
      <p class="text scrollObj">
        The project uses a variety of building environmental protection
        materials, wallpaper, pipe, paint, and a number of environmental
        protection lighting, which is a kind of lighting system for the purpose
        of saving electric energy and protecting the environment. Through
        scientific lighting design, the use of efficient, safe, high-quality
        lighting electrical products, to create a comfortable, economic,
        beneficial lighting environment.
      </p>
      <p class="text scrollObj">
        当然也经受了磨难，最大的困难在于根植于本土文脉的不可复制性，东西方在艺术与生活上不同感官与体验的结合，现代与传统的融合。从设计中体验生活，从生活中体验艺术。
      </p>
      <p class="text scrollObj">
        Of course, they have also experienced difficulties. The biggest
        difficulty lies in the unrepeatability rooted in the local context, the
        combination of different senses and experiences in art and life between
        the East and the west, and the integration of modern and traditional.
        Experience life from design and art from life.
      </p>
      <p class="img scrollObj">
        <img src="@/assets/images/news/d-4.png" alt="" />
      </p>
      <p class="text scrollObj">所有的付出都会得到回报和肯定。</p>
      <p class="text scrollObj">
        All the efforts have been rewarded and affirmed.
      </p> -->
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
const defaultBottom = 0;
export default {
  name: "page-news-detail",
  props: ["id"],
  data() {
    return {
      offsetBottom: defaultBottom,
      getBottom: false,
      detail: null
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  directives: {
    ahtml: {
      bind(el, binding) {
        const { value } = binding;
        el.innerHTML = value;
        const p = Array.from(el.querySelectorAll("p"));
        p.forEach(item => {
          if (item.querySelector("img")) {
            item.classList.add("img", "scrollObj");
          } else {
            item.classList.add("text", "scrollObj");
          }
        });
      },
      inserted: function(el) {
        console.log(el);
      }
    }
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      const that = this;
      that.PAGE_LOADING();
      that.getDetail().then(res => {
        that.detail = res.data;
        that.$nextTick(() => {
          that.PAGE_LOADED();
          window.addEventListener("scroll", that.scrollComputed);
        });
      });
    },
    scrollComputed() {
      const screenHeight = window.innerHeight;
      const dom = this.$refs["p-container"];
      const bcr = dom.getBoundingClientRect();
      const { bottom } = bcr;
      this.getBottom = bottom <= screenHeight;
      // if (bottom <= screenHeight && screenHeight - bottom >= 100) {
      //   this.offsetBottom = screenHeight - bottom;
      // } else {
      //   this.offsetBottom = defaultBottom;
      // }
      // console.log(this.offsetBottom);
    },
    getDetail() {
      return this.$request.get(`/api/news/${this.id}`);
    }
  }
};
</script>

<style></style>
